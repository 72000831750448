import { createRouter, createWebHistory } from "vue-router"
import AppLayout from "@/layout/AppLayout.vue"
import { useAuthStore } from "@/stores/authStore"
import sellerService from "@/api/sellerService"
import userService from "@/api/userService"
import LoggingService from "@/api/loggingService"
import { EventTypes } from "@/api/loggingService"
import { checkSubdomain } from "@/utils/subdomain"

// Constants for user roles and onboarding statuses
const UserRoles = {
	AGENT: "agent",
	SELLER: "seller",
	COURIER: "courier",
	ADMIN: "admin",
	USER: "user",
}

const UserOnboardingStatus = {
	VISITED: "visited",
	SHARED_NUMBER: "shared_number",
	REGISTERED: "registered",
}

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("@/views/Home.vue"),
		meta: { requiresAuth: false },
		beforeEnter: async (to, from, next) => {
			if (checkSubdomain()) {
				return next({
					name: "public-store",
				})
			}
			return next()
		},
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/auth/Login.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/store",
		name: "public-store",
		component: () => import("@/views/store/StoreFront.vue"),
		props: true,
		meta: { requiresAuth: false },
	},
	{
		path: "/register/seller",
		name: "register-seller",
		component: () => import("@/views/auth/RegisterSeller.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/seller-registration-pending/:sellerName?",
		name: "seller-registration-pending",
		component: () =>
			import("@/views/auth/registration/RegistrationPending.vue"),
		props: true,
		meta: { requiresAuth: true },
	},
	{
		path: "/order/:id",
		name: "order-product",
		component: () => import("@/views/product/OrderProduct.vue"),
		props: true,
		meta: { requiresAuth: false },
	},
	{
		path: "/schedule/:scheduleId",
		name: "schedule-delivery",
		component: () => import("@/views/schedule/ScheduleDelivery.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/schedule/status/:scheduleId",
		name: "schedule-status",
		component: () => import("@/views/schedule/ScheduleStatus.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/dashboard",
		name: "dashboard",
		meta: { requiresAuth: true },
		beforeEnter: async (to, from, next) => {
			const authStore = useAuthStore()
			const userRole = authStore.user?.role

			if (!userRole) {
				return next({ name: "login" })
			}

			// Handle seller onboarding status
			if (userRole === UserRoles.SELLER) {
				let onboardingStatus = authStore.user.onboardingStatus

				if (!onboardingStatus) {
					onboardingStatus = await userService.getUserOnboardingStatus(
						authStore.user.id
					)
					authStore.user.onboardingStatus = onboardingStatus
				}

				if (onboardingStatus === UserOnboardingStatus.VISITED) {
					return next({ name: "register-seller" })
				}

				if (onboardingStatus === UserOnboardingStatus.SHARED_NUMBER) {
					return next({ name: "register-seller" })
				}

				if (onboardingStatus === UserOnboardingStatus.REGISTERED) {
					let seller = authStore.user.seller

					if (!seller) {
						seller = await sellerService.getSellerByUserId(authStore.user.id)
						authStore.user.seller = seller
					}

					if (seller.status === "pending") {
						return next({
							name: "seller-registration-pending",
							params: { sellerName: seller.fullName },
						})
					}
				}
			}

			// Redirect to the user's specific dashboard
			const dashboardRoute = getDashboardRouteByRole(userRole)
			return next(dashboardRoute)
		},
	},
	{
		path: "/agent/",
		component: AppLayout,
		meta: { requiresAuth: true, role: UserRoles.AGENT },
		children: [
			{
				path: "dashboard",
				name: "agent-dashboard",
				component: () => import("@/views/agent/Dashboard.vue"),
			},
			{
				path: "orders",
				name: "agent-orders",
				component: () => import("@/views/agent/Orders.vue"),
			},
			{
				path: "order/:id",
				name: "agent-order-detail",
				component: () => import("@/views/order/OrderDetails.vue"),
			},
			{
				path: "create-order",
				name: "agent-create-order",
				component: () => import("@/views/order/CreateOrder.vue"),
			},
			{
				path: "receive-order",
				name: "ReceiveOrder",
				component: () => import("@/views/agent/RecieveOrder.vue"),
			},
			{
				path: "handoff-order",
				name: "HandoffOrder",
				component: () => import("@/views/agent/HandoffOrder.vue"),
			},
			{
				path: "profile",
				name: "agent-profile",
				component: () => import("@/views/agent/Profile.vue"),
			},
		],
	},
	{
		path: "/seller/",
		component: AppLayout,
		meta: { requiresAuth: true, role: UserRoles.SELLER },
		children: [
			{
				path: "dashboard",
				name: "seller-dashboard",
				component: () => import("@/views/seller/Dashboard.vue"),
			},
			{
				path: "create-order",
				name: "seller-create-order",
				component: () => import("@/views/order/CreateOrder.vue"),
			},
			{
				path: "enter-product/:id?",
				name: "seller-enter-product",
				component: () => import("@/views/product/CreateProduct.vue"),
				props: true,
			},
			{
				path: "agents",
				name: "seller-agents",
				component: () => import("@/views/seller/Agents.vue"),
			},
			{
				path: "agent/:id",
				name: "seller-agent-detail",
				component: () => import("@/views/seller/AgentDetails.vue"),
			},
			{
				path: "orders",
				name: "seller-orders",
				component: () => import("@/views/seller/Orders.vue"),
			},
			{
				path: "products",
				name: "seller-products",
				component: () => import("@/views/seller/Products.vue"),
			},
			{
				path: "order/:id",
				name: "seller-order-detail",
				component: () => import("@/views/order/OrderDetails.vue"),
			},
			{
				path: "product/:id",
				name: "seller-product-detail",
				component: () => import("@/views/product/ProductDetails.vue"),
			},
			{
				path: "profile",
				name: "seller-profile",
				component: () => import("@/views/seller/Profile.vue"),
			},
		],
	},
	// Uncomment and adjust as needed
	// {
	//   path: "/courier/",
	//   component: AppLayout,
	//   meta: { requiresAuth: true, role: UserRoles.COURIER },
	//   children: [
	//     // Courier routes
	//   ],
	// },
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: () => import("@/views/404.vue"),
		meta: { requiresAuth: false },
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.onError((error, to) => {
	if (
		error.message.includes("Failed to fetch dynamically imported module") ||
		error.message.includes("Importing a module script failed")
	) {
		console.log("Error:", error)
		window.location = to.fullPath
	}
})

// Map user roles to their respective dashboard route names
const DASHBOARD_ROUTES = {
	[UserRoles.AGENT]: { name: "agent-dashboard" },
	[UserRoles.SELLER]: { name: "seller-dashboard" },
	[UserRoles.COURIER]: { name: "courier-dashboard" },
	[UserRoles.ADMIN]: { name: "admin-dashboard" },
}

const getDashboardRouteByRole = role => {
	return DASHBOARD_ROUTES[role] || { name: "NotFound" }
}

// Update the afterEach hook
router.afterEach((to, from) => {
	LoggingService.logEvent(EventTypes.NAVIGATION, "route_change", {
		from: from.fullPath,
		to: to.fullPath,
	})
})

router.beforeEach(async (to, from, next) => {
	try {
		const authStore = useAuthStore()
		// If not authenticated, try auto-login using secure cookies
		if (!authStore.isAuthenticated) {
			await authStore.autoLogin()
		}

		if (to.meta.requiresAuth && !authStore.isAuthenticated) {
			return next({
				name: "login",
				query: { redirect: to.fullPath },
			})
		}

		if (to.meta.role && to.meta.role !== authStore.user?.role) {
			const dashboardRoute = getDashboardRouteByRole(authStore.user?.role)
			return next(dashboardRoute)
		}

		return next()
	} catch (error) {
		console.error("Navigation guard error:", error)
		const authStore = useAuthStore()
		authStore.setUser(null)
		return next({
			name: "login",
			query: { redirect: to.fullPath },
		})
	}
})

export default router
