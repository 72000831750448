import { defineStore } from "pinia"

const locale = localStorage.getItem("locale") || "አማ"

export const useMainStore = defineStore("main", {
	state: () => ({
		locale: locale,
	}),
	getters: {
		isSubdomain: () => {
			// check if subdomain is available
			const host = window.location.hostname
			return host.includes('.')
		}
	},
	actions: {
		setLocale(newLocale) {
			if (newLocale === "en" || newLocale === "አማ") {
				this.locale = newLocale
				localStorage.setItem("locale", newLocale)
			} else {
				console.warn(`Invalid locale: ${newLocale}. Defaulting to 'en'.`)
				this.locale = "en"
				localStorage.setItem("locale", "en")
			}
		},
	},
})
