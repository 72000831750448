import { defineStore } from "pinia"
import { useMainStore } from "@/stores/main"
import { userService, authService } from "@/api"
import router from "@/router"
import { ApiError, handleError } from "@/utils/errorHandler"

const TOKEN_REFRESH_INTERVAL = 15 * 60 * 1000 // 15 minutes

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    isAuthenticated: false,
    lastValidationTime: null,
  }),

  getters: {
    isTokenExpired(state) {
      if (!state.lastValidationTime) return true
      return Date.now() - state.lastValidationTime > TOKEN_REFRESH_INTERVAL - 60 * 1000
    },
  },

  actions: {
    setUser(user, validationTime = Date.now()) {
      if (!user) {
        localStorage.removeItem("user")
        this.user = null
        this.isAuthenticated = false
        this.lastValidationTime = null
        return
      }

      const userToSave = {
        id: user.id,
        role: user.role,
        lang: user.lang,
        phone_number: user.phone_number,
        onboardingStatus: user.onboardingStatus
      }

      localStorage.setItem("user", JSON.stringify(userToSave))
      localStorage.setItem("lastValidationTime", validationTime.toString())
      
      this.user = userToSave
      this.lastValidationTime = validationTime
      this.isAuthenticated = true

      // Set locale
      const mainStore = useMainStore()
      mainStore.setLocale(user.lang)
    },

    async login(phoneNumber, pin) {
      try {
        const { user } = await authService.login({ phoneNumber, pin })
        this.setUser(user)
        return user
      } catch (error) {
        this.setUser(null)
        throw error
      }
    },

    async refreshSession() {
      try {
        const { user } = await authService.refreshTokens()
        this.setUser(user)
        return true
      } catch (error) {
        this.setUser(null)
        return false
      }
    },

    async logout() {
      try {
        await authService.logout()
      } finally {
        this.setUser(null)
        router.push({ name: "login" })
      }
    },

    async autoLogin() {
      const storedUser = localStorage.getItem("user")
      const storedTime = localStorage.getItem("lastValidationTime")

      if (!storedUser || !storedTime) return false

      this.user = JSON.parse(storedUser)
      this.lastValidationTime = parseInt(storedTime)
      this.isAuthenticated = true

      if (this.isTokenExpired) {
        return await this.refreshSession()
      }

      return true
    },

    // Auth flow helpers
    async signup(phoneNumber) {
      const user = await userService.getUserByPhoneNumber(phoneNumber)
      if (!user) await userService.recordVisit(phoneNumber)
      await authService.createAuthUser(phoneNumber)
      await authService.sendOtp(phoneNumber)
    },

    async verifyOtp(phoneNumber, otp) {
      await authService.verifyOtp(phoneNumber, otp)
      const user = await userService.getUserByPhoneNumber(phoneNumber)
      await userService.updateOnboardingStatus(user.id, "shared_number")
    }
  }
})
