import { computed } from "vue"
import { useI18n as vueUseI18n } from "vue-i18n"
import { useMainStore } from "@/stores/main"

export function useI18n() {
	const { t, locale } = vueUseI18n()
	const mainStore = useMainStore()

	// Initialize locale from the store
	locale.value = mainStore.locale

	const currentLocale = computed({
		get: () => locale.value,
		set: value => {
			locale.value = value
			mainStore.setLocale(value)
		},
	})

	return {
		t,
		currentLocale,
	}
}
