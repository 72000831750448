import { supabase } from "@/api/supabaseConfig"
import { ApiError } from "@/utils/errorHandler"

const productImagesService = {
	async uploadProductImage(file, productId) {
		const fileExt = file.name.split(".").pop()
		const fileName = `${productId}_${Date.now()}.${fileExt}`
		const filePath = `${fileName}`

		const { error: errorStorage } = await supabase.storage
			.from("product_photos")
			.upload(filePath, file)

		if (errorStorage)
			throw new ApiError("500", "UPLOAD_IMAGE_ERROR", errorStorage.message)

		const { data: publicUrl, error: publicUrlError } = supabase.storage
			.from("product_photos")
			.getPublicUrl(filePath)
		if (publicUrlError)
			throw new ApiError("500", "GET_PUBLIC_URL_ERROR", publicUrlError.message)

		const { data: updatedProduct, error: updateError } = await supabase
			.from("product_images")
			.update({ image_url: publicUrl.publicUrl })
			.eq("product_id", productId)

		if (updateError)
			throw new ApiError(
				"500",
				"UPDATE_PRODUCT_IMAGE_ERROR",
				updateError.message
			)

		return publicUrl.publicUrl
	},

	async getProductImagesSortedByHasThumbnail(productId) {
		const { data, error } = await supabase
			.from("product_images")
			.select("image_url")
			.eq("product_id", productId)
			.order("isThumbnail", { ascending: true })
		if (error)
			throw new ApiError("500", "GET_PRODUCT_IMAGES_ERROR", error.message)

		return data.map(item => item.image_url)
	},

	async getThumbnail(productId) {
		const { data, error } = await supabase
			.from("product_images")
			.select("image_url")
			.eq("product_id", productId)
			.eq("isThumbnail", true)

		if (error)
			throw new ApiError("500", "GET_PRODUCT_IMAGES_ERROR", error.message)

		return data[0].image_url
	},

	async getThumbnails(productIds) {
		const { data, error } = await supabase
			.from("product_images")
			.select("product_id, image_url")
			.in("product_id", productIds)
			.eq("isThumbnail", true)

		if (error)
			throw new ApiError("500", "GET_PRODUCT_IMAGES_ERROR", error.message)

		return data
	},
}

export default productImagesService
