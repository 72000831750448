import { toastError } from "@/composables/useToastService"
import { getCurrentInstance } from "vue"

const handleError = (error, vm, info) => {
	let loggingService
	try {
		const instance = getCurrentInstance()
		loggingService =
			instance?.appContext.config.globalProperties.$loggingService
	} catch (e) {
		console.warn("Logging service is not available:", e)
	}

	if (vm) {
		console.error("Component Instance:", vm)
	}
	if (info) {
		console.error("Error Info:", info)
	}

	// Log error to the backend
	// if (loggingService) {
	// 	loggingService.logEvent(
	// 		loggingService.EventTypes.ERROR,
	// 		'error_occurred',
	// 		{
	// 			error_message: error.message,
	// 			error_stack: error.stack,
	// 			component: vm ? vm.$options.name : 'Unknown',
	// 			additional_info: info
	// 		}
	// 	)
	// }

	if (error instanceof UserError || error instanceof ClientError) {
		// Display user-friendly message to the user using toast
		toastError(error.codeString, error.message)
		// console.error(`Error Code: ${error.code} - ${error.message}`)
	} else {
		toastError(error.codeString, error.message)
		console.error("An unexpected error occurred:", error)
	}
}

class UserError extends Error {
	constructor(code, codeString, message, severity = "error", log = false) {
		super(message)
		this.code = code
		this.codeString = codeString
		this.severity = severity
		this.log = log
	}
}

class ClientError extends Error {
	constructor(code, codeString, message, severity = "error", log = false) {
		super(message)
		this.code = code
		this.codeString = codeString
		this.severity = severity
		this.log = log
	}
}
class ApiError extends Error {
	constructor(code, codeString, message, severity = "error", log = false) {
		super(message)
		this.code = code
		this.codeString = codeString
		this.severity = severity
		this.log = log
	}
}

export { UserError, ClientError, ApiError, handleError }
