import { supabase } from "./supabaseConfig"
import { paymentService } from "@/api"
import { UserError, ClientError, ApiError } from "@/utils/errorHandler"

const sellerService = {
	async getSellerById(sellerId) {
		const { data, error } = await supabase
			.from("sellers")
			.select("*")
			.eq("seller_id", sellerId)
			.single()

		if (error)
			throw new ApiError(error.code, "FETCH_SELLER_ERROR", error.message)

		return data
	},
	async registerSeller(sellerData) {
		// Fetch the existing user record
		const { data: user, error: userError } = await supabase
			.from("users")
			.select()
			.eq("id", sellerData.userId)
			.single()

		if (userError) {
			throw new ApiError(userError.code, "FETCH_USER_ERROR", userError.message)
		}

		// Update the user record with new details
		const { data: updatedUser, error: updateUserError } = await supabase
			.from("users")
			.update({
				role: "seller",
				phone_number: sellerData.phoneNumber,
				email: sellerData.email,
				onboarding_status: "registered",
				lang: sellerData.preferredLanguage,
			})
			.eq("id", user.id)
			.select()
			.single()

		if (!updatedUser && !updateUserError.code) {
			throw new UserError(404, "USER_NOT_FOUND", "User not found")
		} else if (updateUserError) {
			throw new ApiError(
				updateUserError.code,
				"UPDATE_USER_ERROR",
				updateUserError.message
			)
		}

		// Now create the seller record using the existing user's ID
		const { data: seller, error: sellerError } = await supabase
			.from("sellers")
			.insert({
				user_id: user.id,
				full_name: sellerData.fullName,
				business_name: sellerData.businessName,
				email: sellerData.email,
				address: sellerData.address,
				item_types: sellerData.itemTypes,
				other_item_type: sellerData.otherItemType,
				daily_deliveries: sellerData.dailyDeliveries,
				status: "pending",
			})
			.select()
			.single()

		if (!seller && !sellerError.code) {
			throw new UserError(404, "SELLER_NOT_FOUND", "Seller not found")
		} else if (sellerError) {
			// If there's an error creating the seller, we should revert the user update
			const { data: revertedUser, error: revertUserError } = await supabase
				.from("users")
				.update({ role: "seller", onboarding_status: "shared_number" })
				.eq("id", user.id)
				.select()
				.single()

			if (!revertedUser && !revertUserError.code) {
				throw new UserError(404, "USER_NOT_FOUND", "User not found")
			} else if (revertUserError) {
				throw new ApiError(
					revertUserError.code,
					"REVERT_USER_ERROR",
					revertUserError.message
				)
			}
		}

		// Create subaccount if bank details are provided
		if (sellerData.bankDetails) {
			const subaccountData = {
				seller_id: seller.seller_id,
				business_name: sellerData.businessName,
				account_name: sellerData.bankDetails.accountName,
				bank_code: sellerData.bankDetails.bankId,
				account_number: sellerData.bankDetails.accountNumber,
			}
			await paymentService.createSubaccount(subaccountData)
		}

		return seller
	},

	async getSellerStatus(sellerId) {
		const { data, error } = await supabase
			.from("sellers")
			.select("status, full_name, business_name")
			.eq("seller_id", sellerId)
			.single()

		if (error) {
			throw new ApiError(error.code, "FETCH_SELLER_ERROR", error.message)
		}
		return data
	},

	async updateSellerInfo(sellerId, sellerData) {
		const { data, error } = await supabase
			.from("sellers")
			.update({
				business_name: sellerData.business_name,
				full_name: sellerData.full_name,
				email: sellerData.email,
				address: sellerData.address,
				item_types: sellerData.item_types,
				daily_deliveries: sellerData.daily_deliveries,
			})
			.eq("seller_id", sellerId)
			.select()

		await supabase
			.from("users")
			.update({ phone_number: sellerData.phoneNumber })
			.eq("id", data[0].user_id)

		if (!data && !error.code) {
			throw new ClientError(404, "SELLER_NOT_FOUND", "Seller not found")
		} else if (error) {
			throw new ApiError(error.code, "UPDATE_SELLER_ERROR", error.message)
		}
		return data
	},

	async getSellerDashboardData(sellerId) {
		const { data: dashboardData, error: dashboardError } = await supabase.rpc(
			"get_seller_dashboard_data",
			{
				input_seller_id: sellerId,
			}
		)

		if (dashboardError) {
			throw new ApiError(
				dashboardError.code,
				"FETCH_DASHBOARD_DATA_ERROR",
				dashboardError.message
			)
		}
		return dashboardData
	},

	async getSellerOrders(sellerId, page = 1, limit = 10) {
		const { data: orders, error: ordersError } = await supabase
			.from("orders")
			.select("*")
			.eq("seller_id", sellerId)
			.order("created_at", { ascending: false })
			.range((page - 1) * limit, page * limit - 1)

		if (ordersError) {
			throw new ApiError(
				ordersError.code,
				"FETCH_ORDERS_ERROR",
				ordersError.message
			)
		}

		return orders
	},

	async getSellerByUserId(userId) {
		const { data, error } = await supabase
			.from("sellers")
			.select("*")
			.eq("user_id", userId)
			.single()

		if (error)
			throw new ApiError(error.code, "FETCH_SELLER_ERROR", error.message)

		return data
	},

	async getSellerAccountDetails(sellerId) {
		const { data, error } = await supabase
			.from("subaccounts")
			.select("*")
			.eq("seller_id", sellerId)
			.single()

		if (error)
			throw new ApiError(error.code, "FETCH_SUBACCOUNT_ERROR", error.message)
		return data
	},

	async getSellerItemTypes(sellerId) {
		const { data, error } = await supabase
			.from("sellers")
			.select("item_types")
			.eq("seller_id", sellerId)
			.single()
		if (error)
			throw new ApiError(error.code, "FETCH_ITEM_TYPES_ERROR", error.message)
		return data.item_types
	},
}

export default sellerService
