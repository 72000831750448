import { supabase } from "@/api/supabaseConfig"
import { ApiError } from "@/utils/errorHandler"
import bcrypt from "bcryptjs"

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL

const authService = {
	async login({ phoneNumber, pin }) {
		if (!phoneNumber || !pin) {
			throw new ApiError(400, "VALIDATION_ERROR", "Phone number and PIN required")
		}

		const response = await fetch(`${SUPABASE_URL}/functions/v1/auth`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			credentials: "include",
			body: JSON.stringify({ phone_number: phoneNumber, pin: pin })
		})

		if (!response.ok) {
			const { error } = await response.json().catch(() => ({}))
			throw new ApiError(response.status, "LOGIN_ERROR", error?.message || "Invalid phone number or PIN")
		}

		return await response.json()
	},

	async refreshTokens() {
		const response = await fetch(`${SUPABASE_URL}/functions/v1/auth`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			credentials: "include"
		})

		if (!response.ok) {
			throw new ApiError(response.status, "TOKEN_REFRESH_ERROR", "Failed to refresh session")
		}

		return await response.json()
	},

	async logout() {
		await fetch(`${SUPABASE_URL}/functions/v1/auth`, {
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
			credentials: "include"
		})
	},

	// Auth flow helpers
	async createAuthUser(phoneNumber) {
		const { data: user, error: userError } = await supabase
			.from("users")
			.select("*")
			.eq("phone_number", phoneNumber)
			.single()

		if (userError && userError.code !== "PGRST116") {
			throw new ApiError(userError.code, "FETCH_USER_ERROR", userError.message)
		}

		const { error: authError } = await supabase
			.from("auth")
			.upsert({ user_id: user.id }, { onConflict: "user_id" })

		if (authError) {
			throw new ApiError(authError.code, "CREATE_AUTH_ERROR", authError.message)
		}
	},

	async setPin(userId, pin) {
		const hashedPIN = await bcrypt.hash(pin, 10)
		
		const { error: authError } = await supabase
			.from("auth")
			.upsert({ user_id: userId, pin: hashedPIN }, { onConflict: "user_id" })

		if (authError) {
			throw new ApiError(authError.code, "SET_PIN_ERROR", authError.message)
		}

		const { error: userError } = await supabase
			.from("users")
			.update({ has_pin: true })
			.eq("id", userId)

		if (userError) {
			throw new ApiError(userError.code, "UPDATE_USER_ERROR", userError.message)
		}
	},

	async sendOtp(phoneNumber) {
		const response = await fetch(`${SUPABASE_URL}/functions/v1/sendOtp`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ to: phoneNumber })
		})

		if (!response.ok) {
			throw new ApiError(response.status, "SEND_OTP_ERROR", "Failed to send OTP")
		}
	},

	async verifyOtp(phoneNumber, otp) {
		const response = await fetch(`${SUPABASE_URL}/functions/v1/verifyOtp`, {
			method: "POST", 
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ to: phoneNumber, code: otp })
		})

		if (!response.ok) {
			throw new ApiError(response.status, "VERIFY_OTP_ERROR", "Failed to verify OTP")
		}
	},

	async signup({ phoneNumber, pin }) {
		if (!phoneNumber || !pin) {
			throw new ApiError(400, "VALIDATION_ERROR", "Phone number and PIN required")
		}

		const response = await fetch(`${SUPABASE_URL}/functions/v1/auth/signup`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			credentials: "include",
			body: JSON.stringify({ 
				phone_number: phoneNumber, 
				pin
			})
		})

		if (!response.ok) {
			const { error } = await response.json().catch(() => ({}))
			if (response.status === 409) {
				throw new ApiError(409, "USER_EXISTS", "User already exists")
			}
			throw new ApiError(
				response.status, 
				"SIGNUP_ERROR", 
				error?.message || "Signup failed"
			)
		}

		return await response.json()
	}
}

export default authService
