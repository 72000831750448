<script setup>

import { ref } from "vue"

const pullDistance = ref(0)
const isPulling = ref(false)
const startY = ref(0)
const threshold = 100 // Adjust this value to change the pull distance required to trigger refresh

const touchStart = e => {
	startY.value = e.touches[0].clientY
}

const touchMove = e => {
	const currentY = e.touches[0].clientY
	const diff = currentY - startY.value

	if (diff > 0 && window.scrollY === 0) {
		pullDistance.value = Math.min(diff / 2, threshold)
		isPulling.value = true
		e.preventDefault()
	}
}

const touchEnd = () => {
	if (pullDistance.value >= threshold) {
		window.location.reload()
	}

	pullDistance.value = 0
	isPulling.value = false
}
</script>

<template>
	<div
		class="pull-to-refresh"
		@touchstart="touchStart"
		@touchmove="touchMove"
		@touchend="touchEnd"
	>
		<div
			class="pull-down-content"
			:style="{ transform: `translateY(${pullDistance}px)` }"
		>
			<div v-if="isPulling" class="pull-down-indicator">
				<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
			</div>
		</div>
		<slot></slot>
	</div>
</template>

<style scoped>
.pull-to-refresh {
	overflow: hidden;
}

.pull-down-content {
	transition: transform 0.2s;
}

.pull-down-indicator {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
}
</style>
