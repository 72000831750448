import { createApp, watch } from "vue"
import { createHead } from "@unhead/vue"
import App from "./App.vue"
import router from "./router"
import { createPinia } from "pinia"
import { createI18n } from "vue-i18n"
import { setToastInstance } from "@/composables/useToastService"

import PrimeVue from "primevue/config"
import ToastService from "primevue/toastservice"
import DialogService from "primevue/dialogservice"
import ConfirmationService from "primevue/confirmationservice"

import LoggingService from "@/api/loggingService"
import { EventTypes } from "@/api/loggingService"

import { definePreset } from "@primevue/themes"
import Lara from "@primevue/themes/lara"

import { useMainStore } from "@/stores/main"
import { handleError } from "@/utils/errorHandler"

import en from "@/locale/en.json"
import አማ from "@/locale/አማ.json"

import "@/assets/styles.scss"
import "@/assets/tailwind.css"

const app = createApp(App)
const pinia = createPinia()
const head = createHead()

app.use(head)
app.use(pinia)
app.use(router)

// Global variables
const __TEST = true
const __DEV = true

app.provide("__TEST", __TEST)
app.provide("__DEV", __DEV)

const mainStore = useMainStore()

app.config.globalProperties.$loggingService = LoggingService
app.config.globalProperties.$eventTypes = EventTypes

app.config.globalProperties.$loggingService.log = false

// Internationalization setup
const i18n = createI18n({
	locale: mainStore.locale,
	fallbackLocale: "en",
	legacy: false,
	globalInjection: true,
	warnHtmlMessage: false,
	messages: {
		en,
		አማ,
	},
})

watch(
	() => mainStore.locale,
	newLocale => {
		i18n.global.locale.value = newLocale
	}
)

app.use(i18n)

// PrimeVue theme setup
const MyPreset = definePreset(Lara, {
	semantic: {
		primary: {
			50: "{amber.50}",
			100: "{amber.100}",
			200: "{amber.200}",
			300: "{amber.300}",
			400: "{amber.400}",
			500: "{amber.500}",
			600: "{amber.600}",
			700: "{amber.700}",
			800: "{amber.800}",
			900: "{amber.900}",
			950: "{amber.950}",
		},
	},
})

app.use(PrimeVue, {
	ripple: true,
	theme: {
		preset: MyPreset,
		options: {
			darkModeSelector: ".app-dark",
		},
	},
})

app.use(ToastService)
app.use(DialogService)
app.use(ConfirmationService)

// Error handling
app.config.errorHandler = (err, vm, info) => {
	handleError(err, vm, info)
}

// Set the toast instance for the myToastService
setToastInstance(app.config.globalProperties.$toast)

// Mount the app
app.mount("#app")
