export const SubdomainTypes = {
	MAIN: "main",
	SUBDOMAIN: "subdomain",
}

export const getSubdomain = () => {
	const hostname = window.location.hostname
	const parts = hostname.split(".")

	// Handle localhost with subdomains (e.g., store.localhost)
	if (hostname.endsWith("localhost")) {
		return parts.length > 1 ? parts[0] : SubdomainTypes.MAIN
	}

	// Handle IP addresses
	if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
		return SubdomainTypes.MAIN
	}

	// Return MAIN for basic domains (e.g., example.com)
	if (parts.length === 2) {
		return SubdomainTypes.MAIN
	}

	// Return subdomain for valid subdomains (e.g., store.example.com)
	return parts.length > 2 ? parts[0] : SubdomainTypes.MAIN
}

export const checkSubdomain = function () {
	const hostname = window.location.hostname
	const parts = hostname.split(".")

	// Handle localhost with subdomains (e.g., store.localhost)
	if (hostname.endsWith("localhost")) {
		return parts.length > 1
	}

	// Handle IP addresses
	if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) return false

	// Check if it's a basic domain (e.g., example.com)
	if (parts.length === 2) return false

	// Check if it's a subdomain (e.g., store.example.com)
	return parts.length > 2
}
