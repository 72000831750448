<script setup>
import { computed, onMounted } from "vue"
import { useMainStore } from "@/stores/main"
import { useStoreStore } from "@/stores/storeStore"
import { getSubdomain, SubdomainTypes } from "@/utils/subdomain"

const mainStore = useMainStore()
const storeStore = useStoreStore()
const logoUrl = computed(() => `/layout/images/pickup-logo.png`)
const isSubdomain = computed(() => getSubdomain() !== SubdomainTypes.MAIN)

onMounted(async () => {
	if (isSubdomain.value) {
		await storeStore.fetchStoreBySubdomain()
	}
})
</script>

<template>
	<div class="layout-topbar">
		<div v-if="!isSubdomain" class="layout-topbar-logo-container">
			<router-link to="/" class="layout-topbar-logo">
				<img :src="logoUrl" alt="logo" />
			</router-link>
		</div>
		<div
			v-else
			class="fixed top-0 left-0 right-0 bg-white shadow-md z-50 px-4 py-2"
		>
			<div>
				<router-link to="/" class="flex items-center justify-between">
					<div class="flex flex-col gap-2">
						<span class="text-xl font-bold">{{
							storeStore.store?.store_name
						}}</span>
					</div>
					<img :src="logoUrl" alt="logo" class="h-10 w-auto" />
				</router-link>
			</div>
		</div>
	</div>
</template>
