import { supabase } from "@/api/supabaseConfig"
import { UserError, ApiError } from "@/utils/errorHandler"

const UserOnboardingStatus = {
	VISITED: "visited",
	SHARED_NUMBER: "shared_number",
	REGISTERED: "registered",
}

const userService = {
	async getUserById(userId) {
		const { data, error } = await supabase
			.from("users")
			.select("*")
			.eq("id", userId)
			.single()
		if (error && error.code !== "PGRST116")
			throw ApiError(error.code, "FETCH_USER_ERROR", error.message)
		return data
	},
	async recordVisit(phoneNumber) {
		const { data, error } = await supabase
			.from("users")
			.select("*")
			.eq("phone_number", phoneNumber)
			.single()

		if (error && error.code === "PGRST116") {
			const { data: user, error } = await supabase
				.from("users")
				.insert({
					phone_number: phoneNumber,
					onboarding_status: UserOnboardingStatus.VISITED,
					role: "seller",
					lang: "አማ",
				})
				.select()
				.single()
			if (error)
				throw ApiError(error.code, "CREATE_VISITOR_USER_ERROR", error.message)

			return user
		}
	},
	async getUserByPhoneNumber(phoneNumber) {
		const { data: user, error } = await supabase
			.from("users")
			.select("*")
			.eq("phone_number", phoneNumber)
			.single()

		if (!user && error?.code === "PGRST116") return null
		else if (error)
			throw ApiError(error.code, "FETCH_USER_ERROR", error.message)
		return user
	},
	async updateOnboardingStatus(user_id, status) {
		const { data: user, error } = await supabase
			.from("users")
			.update({ onboarding_status: status })
			.eq("id", user_id)
			.select()
			.single()

		if (error)
			throw ApiError(
				error.code,
				"UPDATE_ONBOARDING_STATUS_FAILED",
				error.message
			)
		return user
	},
	async createUser(phoneNumber) {
		const { data: user, error } = await supabase
			.from("users")
			.upsert(
				{
					phone_number: phoneNumber,
					onboarding_status: UserOnboardingStatus.SHARED_NUMBER,
					role: "seller",
					lang: "አማ",
				},
				{
					onConflict: "phone_number",
					ignoreDuplicates: false,
				}
			)
			.select()
			.single()

		if (error) throw ApiError(error.code, "CREATE_USER_FAILED", error.message)

		return user
	},
	async getUserDataById(userId) {
		let { data, error } = await supabase
			.from("users")
			.select("*")
			.eq("id", userId)
			.single()

		if (error) throw new ApiError(error.code, "FETCH_USER_ERROR", error.message)

		switch (data.role) {
			case "seller": {
				const { data: sellerData, error: sellerError } = await supabase
					.from("sellers")
					.select("*")
					.eq("user_id", userId)
					.single()

				if (sellerError)
					throw new ApiError(
						sellerError.code,
						"FETCH_SELLER_ERROR",
						sellerError.message
					)
				data = { ...data, ...sellerData }
				break
			}
			case "courier": {
				const { data: courierData, error: courierError } = await supabase
					.from("couriers")
					.select("*")
					.eq("user_id", userId)
					.single()
				if (courierError)
					throw new ApiError(
						courierError.code,
						"FETCH_COURIER_ERROR",
						courierError.message
					)
				data = { ...data, ...courierData }
				break
			}
			case "agent": {
				const { data: agentData, error: agentError } = await supabase
					.from("agents")
					.select("*")
					.eq("user_id", userId)
					.single()
				if (agentError)
					throw new ApiError(
						agentError.code,
						"FETCH_AGENT_ERROR",
						agentError.message
					)
				data = { ...data, ...agentData }
				break
			}
			case "admin":
				break
			default:
				throw new UserError(400, "UNKNOWN_USER_ROLE", "Unknown user role")
		}

		return data
	},

	async getUserDataByPhoneNumber(phoneNumber) {
		let { data, error } = await supabase
			.from("users")
			.select("*")
			.eq("phone_number", phoneNumber)
			.single()
		if (error) throw new ApiError(error.code, "FETCH_USER_ERROR", error.message)

		switch (data.role) {
			case "seller": {
				const { data: sellerData, error: sellerError } = await supabase
					.from("sellers")
					.select("*")
					.eq("user_id", data.id)
					.single()
				if (sellerError)
					throw new ApiError(
						sellerError.code,
						"FETCH_SELLER_ERROR",
						sellerError.message
					)
				data = { ...data, ...sellerData }
				break
			}
			case "courier": {
				const { data: courierData, error: courierError } = await supabase
					.from("couriers")
					.select("*")
					.eq("user_id", data.id)
					.single()
				if (courierError)
					throw new ApiError(
						courierError.code,
						"FETCH_COURIER_ERROR",
						courierError.message
					)
				data = { ...data, ...courierData }
				break
			}
			case "agent": {
				const { data: agentData, error: agentError } = await supabase
					.from("agents")
					.select("*")
					.eq("user_id", data.id)
					.single()
				if (agentError)
					throw new ApiError(
						agentError.code,
						"FETCH_AGENT_ERROR",
						agentError.message
					)
				data = { ...data, ...agentData }
				break
			}
			case "admin":
				break
			default:
				throw new Error("Unknown user role")
		}

		return data
	},

	async getUserDataByEmail(email) {
		let { data, error } = await supabase
			.from("users")
			.select("*")
			.eq("email", email)
			.single()
		if (error) throw new ApiError(error.code, "FETCH_USER_ERROR", error.message)

		switch (data.role) {
			case "seller": {
				const { data: sellerData, error: sellerError } = await supabase
					.from("sellers")
					.select("*")
					.eq("user_id", data.id)
					.single()
				if (sellerError)
					throw new ApiError(
						sellerError.code,
						"FETCH_SELLER_ERROR",
						sellerError.message
					)
				data = { ...data, ...sellerData }
				break
			}
			case "courier": {
				const { data: courierData, error: courierError } = await supabase
					.from("couriers")
					.select("*")
					.eq("user_id", data.id)
					.single()
				if (courierError)
					throw new ApiError(
						courierError.code,
						"FETCH_COURIER_ERROR",
						courierError.message
					)
				data = { ...data, ...courierData }
				break
			}
			case "agent": {
				const { data: agentData, error: agentError } = await supabase
					.from("agents")
					.select("*")
					.eq("user_id", data.id)
					.single()
				if (agentError)
					throw new ApiError(
						agentError.code,
						"FETCH_AGENT_ERROR",
						agentError.message
					)
				data = { ...data, ...agentData }
				break
			}
			case "admin":
				break
			default:
				throw ApiError(400, "UNKNOWN_USER_ROLE", "Unknown user role")
		}

		return data
	},

	async getUserDataByTelegramId(telegramId) {
		const { data: user, error } = await supabase
			.from("users")
			.select("*")
			.eq("telegram_user_id", telegramId)
			.single()

		if (error)
			throw ApiError(
				error.code,
				"FETCH_USER_BY_TELEGRAM_ID_FAILED",
				error.message
			)

		return user
	},

	async insertTelegramNumber(telegram_id, telegramPhone) {
		const { data: user, error } = await supabase
			.from("users")
			.update({
				telegram_phone: telegramPhone,
				onboarding_status: UserOnboardingStatus.SHARED_NUMBER,
			})
			.eq("telegram_user_id", telegram_id)
			.select()
			.single()

		if (error)
			throw ApiError(error.code, "INSERT_TELEGRAM_NUMBER_FAILED", error.message)

		return user
	},

	async getUserOnboardingStatus(user_id) {
		const { data: user, error } = await supabase
			.from("users")
			.select("onboarding_status")
			.eq("id", user_id)
			.single()

		if (error)
			throw ApiError(
				error.code,
				"FETCH_ONBOARDING_STATUS_FAILED",
				error.message
			)

		return user.onboarding_status
	},

	async setPreferredLanguage(user_id, locale) {
		const { data: user, error } = await supabase
			.from("users")
			.update({ lang: locale })
			.eq("id", user_id)

		if (error)
			throw ApiError(error.code, "CHANGE_LANGUAGE_FAILED", error.message)

		return user
	},

	async getProfileData(user_id) {
		const { data, error } = await supabase
			.rpc('get_profile_data', { p_user_id: user_id })

		if (error) throw error
		return data
	},
}

export default userService
