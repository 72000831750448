import { defineStore } from "pinia"
import { supabase } from "@/api/supabaseConfig"
import { useRouter } from "vue-router"
import { getSubdomain } from "@/utils/subdomain"
import { ApiError } from "@/utils/errorHandler"
import { productImagesService } from "@/api"
import { SubdomainTypes } from "@/utils/subdomain"

export const useStoreStore = defineStore("storeStore", {
	state: () => ({
		sellerId: null,
		seller: null,
		store: null,
		products: [],
		productsInitialized: false,
	}),
	actions: {
		async fetchStoreBySubdomain() {
			const router = useRouter()
			if (this.store) return this.store

			const subdomain = getSubdomain()
			if (subdomain === SubdomainTypes.MAIN) return null
			const { data, error } = await supabase
				.from("stores")
				.select("*")
				.single()
				.eq("store_subdomain", subdomain)

			if (error) {
				await router.push({ name: "NotFound" })
				return
			}

			this.store = data
			this.sellerId = data.seller_id
			return data
		},
		async fetchCountProductsBySellerId() {
			if (!this.sellerId) return 0
			const { count, error: countError } = await supabase
				.from("products")
				.select("*", { count: "exact" })
				.eq("seller_id", this.sellerId)

			if (countError)
				throw new ApiError(
					countError.code,
					"FETCH_PRODUCTS_FAILED",
					countError.message
				)
			return count
		},
		async fetchProductsBySellerId(from, to) {
			if (!this.sellerId || !this.store) return []
			if (!this.productsInitialized) {
				const totalRecords = await this.fetchCountProductsBySellerId()
				this.products = Array(totalRecords).fill({})
				this.productsInitialized = true
			} else if (this.productsInitialized && this.products[to]?.product_id)
				return this.products.slice(from, to + 1)

			const { data: products, error } = await supabase
				.from("products")
				.select("*")
				.eq("seller_id", this.sellerId)
				.range(from, to)

			if (error)
				if (error?.code === "PGRST116") return []
				else
					throw new ApiError(error.code, "FETCH_PRODUCTS_FAILED", error.message)

			await Promise.all(
				products.map(async product => {
					product.image_urls =
						await productImagesService.getProductImagesSortedByHasThumbnail(
							product.product_id
						)
					console.log(product.image_urls)
				})
			)
			this.products.splice(from, products.length, ...products)
			return products
		},
		async fetchSellerById() {
			if (this.seller) return this.seller
			if (!this.sellerId) return null

			const { data, error } = await supabase
				.from("sellers")
				.select("*")
				.eq("seller_id", this.sellerId)
				.single()

			if (error) return null
			this.seller = data
			return data
		},
		async fetchCategories() {
			const { data, error } = await supabase.rpc(
				"fetch_active_categories_with_count",
				{ seller_id_param: this.sellerId }
			)
			if (error)
				throw new ApiError(error.code, "FETCH_CATEGORIES_FAILED", error.message)

			return data
		},
	},
})
