<script setup>
import { onMounted } from "vue"
import { RouterView } from "vue-router"
import Toast from "primevue/toast"
import Dialog from "primevue/dialog"
import ConfirmDialog from "primevue/confirmdialog"
import { useAuthStore } from "@/stores/authStore"
import PullToRefresh from "@/components/PullToRefresh.vue"

const authStore = useAuthStore()

onMounted(async () => {
	console.log("App mounted")
})
</script>

<template>
	<PullToRefresh @refresh="refreshData">
		<div>
			<RouterView />
		<Toast />
		<Dialog />
			<ConfirmDialog />
		</div>
	</PullToRefresh>
</template>

<style scoped></style>
