let toastInstance = null

export function setToastInstance(instance) {
	toastInstance = instance
}

const lifeTime = 3000

export function toastInfo(title, body) {
	if (toastInstance) {
		toastInstance.add({
			severity: "info",
			summary: title,
			detail: body,
			life: lifeTime,
		})
	} else {
		console.warn("Toast instance not set. Message:", title, body)
	}
}

export function toastWarn(title, body) {
	if (toastInstance) {
		toastInstance.add({
			severity: "warn",
			summary: title,
			detail: body,
			life: lifeTime,
		})
	} else {
		console.warn("Toast instance not set. Warning:", title, body)
	}
}

export function toastError(title, body) {
	if (toastInstance) {
		toastInstance.add({
			severity: "error",
			summary: title,
			detail: body,
			life: lifeTime,
		})
	} else {
		console.warn("Toast instance not set. Error:", title, body)
	}
}
