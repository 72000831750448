import { supabase } from "./supabaseConfig"
import { UserError, ApiError } from "@/utils/errorHandler"

const paymentService = {
	async createPayment(paymentData) {
		const { data, error } = await supabase
			.from("payments")
			.insert([paymentData])
			.select()

		if (!data && !error.code) {
			throw new UserError(404, "PAYMENT_NOT_CREATED", "Payment not created")
		} else if (error) {
			throw new ApiError(error.code, "CREATE_PAYMENT_ERROR", error.message)
		}
		return data[0]
	},

	async getPaymentByOrderId(orderId) {
		const { data, error } = await supabase
			.from("payments")
			.select("*")
			.eq("order_id", orderId)
			.single()

		if (error) {
			throw new ApiError(
				error.code,
				"GET_PAYMENT_BY_ORDER_ID_ERROR",
				error.message
			)
		}
		return data
	},

	async updatePayment(paymentId, updateData) {
		const { data, error } = await supabase
			.from("payments")
			.update(updateData)
			.eq("payment_id", paymentId)
			.select()

		if (!data && !error.code) {
			throw new UserError(404, "PAYMENT_NOT_FOUND", "Payment not found")
		} else if (error) {
			throw new ApiError(error.code, "UPDATE_PAYMENT_ERROR", error.message)
		}
		return data[0]
	},

	async createSubaccount(subaccountData) {
		try {
			console.log(subaccountData)
			const { data, error } = await supabase
				.from("subaccounts")
				.insert([
					{
						seller_id: subaccountData.seller_id,
						account_name: subaccountData.account_name,
						bank_code: subaccountData.bank_code,
						account_number: subaccountData.account_number,
					},
				])
				.select()

			if (!data && !error.code) {
				throw new UserError(
					404,
					"SUBACCOUNT_NOT_CREATED",
					"Subaccount not created"
				)
			} else if (error) {
				throw new ApiError(error.code, "CREATE_SUBACCOUNT_ERROR", error.message)
			}

			return data[0]
		} catch (error) {
			console.error("Error creating subaccount:", error)
			throw error
		}
	},

	async updateSubaccount(subaccountData) {
		try {
			const { data, error } = await supabase
				.from("subaccounts")
				.update({
					business_name: subaccountData.business_name,
					account_name: subaccountData.account_name,
					bank_code: subaccountData.bank_code,
					account_number: subaccountData.account_number,
				})
				.eq("seller_id", subaccountData.seller_id)
				.select()

			if (!data && !error.code) {
				throw new UserError(404, "SUBACCOUNT_NOT_FOUND", "Subaccount not found")
			} else if (error) {
				throw new ApiError(error.code, "UPDATE_SUBACCOUNT_ERROR", error.message)
			}
			return data[0]
		} catch (error) {
			console.error("Error updating subaccount:", error)
			throw error
		}
	},

	async getBankName(bankCode) {
		const { data, error } = await supabase
			.from("banks")
			.select("name")
			.eq("id", bankCode)
			.single()

		if (error) {
			throw new ApiError(error.code, "GET_BANK_NAME_ERROR", error.message)
		}
		return data.name
	},

	async getBankDetails(bankSlug) {
		const { data, error } = await supabase
			.from("banks")
			.select("*")
			.eq("slug", bankSlug)
			.single()

		if (error) {
			throw new ApiError(error.code, "GET_BANK_DETAILS_ERROR", error.message)
		}
		return data
	},

	async getAllBanks() {
		const { data, error } = await supabase
			.from("banks")
			.select("*")
			.order("name")

		if (error) {
			throw new ApiError(error.code, "GET_ALL_BANKS_ERROR", error.message)
		}
		return data
	},

	async getActiveBanks() {
		const { data, error } = await supabase
			.from("banks")
			.select("*")
			.eq("is_active", true)
			.order("name", { ascending: true })

		if (error) {
			throw new ApiError(error.code, "GET_ACTIVE_BANKS_ERROR", error.message)
		}
		return data
	},
}

export default paymentService
