import { createClient } from "@supabase/supabase-js"
import { ApiError } from "@/utils/errorHandler"
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY

const supabase = createClient(supabaseUrl, supabaseKey)

// Supabase helper functions
const supabaseHelpers = {
	// Upload file to Supabase Storage
	async uploadFile(bucket, path, file) {
		const { data, error } = await supabase.storage
			.from(bucket)
			.upload(path, file)

		if (error)
			throw new ApiError(error.code, "UPLOAD_FILE_ERROR", error.message)
		return data
	},

	// Get public URL for a file in Supabase Storage
	getPublicUrl(bucket, path) {
		const { data } = supabase.storage.from(bucket).getPublicUrl(path)
		return data.publicUrl
	},

	// Delete file from Supabase Storage
	async deleteFile(bucket, path) {
		const { data, error } = await supabase.storage.from(bucket).remove([path])
		if (error)
			throw new ApiError(error.code, "DELETE_FILE_ERROR", error.message)
		return data
	},

	// Generic function to fetch data from a table
	async fetchData(table, query = {}) {
		let supabaseQuery = supabase.from(table).select()

		if (query.filter) {
			Object.entries(query.filter).forEach(([key, value]) => {
				supabaseQuery = supabaseQuery.eq(key, value)
			})
		}

		if (query.order) {
			supabaseQuery = supabaseQuery.order(query.order.column, {
				ascending: query.order.ascending,
			})
		}

		if (query.limit) {
			supabaseQuery = supabaseQuery.limit(query.limit)
		}

		const { data, error } = await supabaseQuery

		if (error) throw new ApiError(error.code, "FETCH_DATA_ERROR", error.message)
		return data
	},

	// Generic function to insert data into a table
	async insertData(table, data) {
		const { data: insertedData, error } = await supabase
			.from(table)
			.insert(data)
			.select()

		if (error)
			throw new ApiError(error.code, "INSERT_DATA_ERROR", error.message)
		return insertedData
	},

	// Generic function to update data in a table
	async updateData(table, id_field, data) {
		const { data: updatedData, error } = await supabase
			.from(table)
			.update(data)
			.eq(id_field, data[id_field])
			.select()

		if (error)
			throw new ApiError(error.code, "UPDATE_DATA_ERROR", error.message)
		return updatedData
	},

	// Generic function to delete data from a table
	async deleteData(table, id_field, id) {
		const { data, error } = await supabase.from(table).delete().eq(id_field, id)

		if (error)
			throw new ApiError(error.code, "DELETE_DATA_ERROR", error.message)
		return data
	},
}

export { supabase, supabaseHelpers }
